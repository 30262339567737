import { Col, Menu, Row } from "antd";
import UserDetailTab from "./detailPage";
import { useState } from "react";
import UserTransactionList from "./transactionList";
import TwodUserLogsDetail from "../game_twod_threed/twodUserLogs";
import ThreedUserLogs from "../game_twod_threed/threedUserLogs";
import PPUserDetail from "../game_pp/pp_user_detail";

export default function UserDetail({ detail, closeDrawer }) {
  const [key, setKey] = useState("detail");
  const items = [
    {
      label: "Detail",
      key: "detail",
    },
    {
      label: "Transactions",
      key: "transaction",
    },
    {
      label: "TwoD",
      key: "twod",
    },
    {
      label: "ThreeD",
      key: "threed",
    },
    {
      label: "PP SLOT",
      key: "pp_sl",
    },
  ];

  function bodyHandler() {
    switch (true) {
      case key === "transaction":
        return <UserTransactionList data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "twod":
        return <TwodUserLogsDetail data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "threed":
        return <ThreedUserLogs data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === 'pp_sl':
        return <PPUserDetail data={detail} closeDrawer={closeDrawer} />
        break;
      default:
        return <UserDetailTab data={detail} closeDrawer={closeDrawer} />;
        break;
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Menu
          onClick={(e) => setKey(e.key)}
          selectedKeys={[key]}
          mode="horizontal"
          items={items}
        />
      </Col>
      <Col span={24} style={{ padding: "12px", overflowX: "hidden" }}>
        {bodyHandler()}
      </Col>
    </Row>
  );
}
